<template>
  <div class="art-details">
    <div class="header">
      <a-breadcrumb separator=">" style="font-size: 16px; font-weight: 500;">
        <a-breadcrumb-item @click.native="goShowCenter" style="cursor: pointer;">Show Center</a-breadcrumb-item>
        <a-breadcrumb-item>Details</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="body-container">
      <div class="video-container">
        <video
          class="video"
          ref="videoPlayer"
          :src="item.videoUrl"
          :muted="item.muted"
          :loop="item.loop"
          :poster="item.poster"
          controls
          autoplay
          @playing="item.isPlaying = true"
          @pause="item.isPlaying = false"
          @ended="item.isPlaying = false">
        </video>
      </div>
      <div class="info">
        <div class="link" @click="viewOnOpensea">View Arts NFT details on Opensea</div>
        <div class="row">
          <div class="label">Contract Address</div>
          <div class="content primary-color">
            <span>{{ contractAddr }}</span>
            <!-- <a-icon class="copy-icon" @click="copyAddress" type="copy"/> -->
          </div>
        </div>
        <div class="row">
          <div class="label">Token ID</div>
          <div class="content">{{ tokenId }}</div>
        </div>
        <div class="row">
          <div class="label">Token Standard</div>
          <div class="content">ERC-1155</div>
        </div>
        <div class="row">
          <div class="label">Blockchain</div>
          <div class="content">Polygon</div>
        </div>
        <div class="row">
          <div class="label">Symbol</div>
          <div class="content">ArtsDAO NFT</div>
        </div>
        <div class="row">
          <div class="label">End time</div>
          <div class="content">{{ endTimeUTC }}</div>
        </div>

        <div class="btn-container" v-if="withdraw.receipt.transactionHash">
          <div class="btn"
            @click="viewDetails"
          >
            View Details
          </div>
        </div>

        <div class="btn-container" v-else>
          <div class="btn"
            :class="{disabled: disabledApplyBtn}"
            @click="applyAirdrop"
          >
            <a-icon v-show="withdraw.loading" class="loading-icon" type="loading"/>
            Apply Now
          </div>
        </div>
        <div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import { errorHandler, getTxDetails, getUTCFormattedDate } from '@/utils/func'

export default {
  name: 'ArtDetails',
  data () {
    return {
      isLoaded: false,
      isLoading: false,
      item: {
        videoUrl: 'https://openseauserdata.com/files/61650cbabc54a63357e13d0f86577315.mov',
        poster: require('@/assets/arts/poster.png'),
        // videoUrl: 'https://openseauserdata.com/files/75663d0592f7f4cac3de6e895a17548e.mp4#t=0.001',
        // poster: 'https://i.seadn.io/gcs/files/712b0a2c47283739a362315d391d1152.jpg?auto=format&dpr=1&w=828',
        muted: true,
        loop: true,
        isPlaying: false
      },

      withdraw: {
        receipt: {},
        loading: false
      },

      endMs: Date.parse('2023-06-18T08:00:00Z'),
      tokenId: '92361708647073240076918521937148781496914298684474954457084531568637930115976',
      tokenId2: '92361708647073240076918521937148781496914298684474954457084531566438906856424'
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId', 'login', 'reverseFullName']),

    contractAddr () {
      return process.env.VUE_APP_TREEDB_ADDR
    },

    endTimeUTC () {
      return getUTCFormattedDate(Math.round(this.endMs / 1000))
    },

    isExpires () {
      return this.endMs - Date.now() < 0
    },

    disabledApplyBtn () {
      return !this.login ||
      !this.address ||
      !this.chainId ||
      !this.reverseFullName ||
      this.isExpires ||
      this.withdraw.loading
    }
  },

  beforeRouteEnter (to, from, next) {
    const len = Object.keys(to.query).length
    const id = parseInt(to.query.id)

    if (len === 0 || id.toString() !== to.query.id || id < 0 || id > 35) {
      next('/arts/show-center')
    } else {
      next()
    }
  },

  created () {
    console.log(this.$route.params)
  },

  methods: {
    goShowCenter () {
      this.$router.replace({ name: 'ShowCenter' })
    },

    viewOnOpensea () {
      window.open(`https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/${this.tokenId}`)
    },

    applyAirdrop () {
      this.withdraw.loading = true

      this.$contracts.artsDAOAirdrop(true).withdraw(this.tokenId)
        .then(result => {
          return result.wait()
        })
        .then(receipt => {
          this.withdraw.receipt = receipt
          this.$message.success('Successfully applied!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.withdraw.loading = false
        })
    },

    viewDetails () {
      getTxDetails(process.env.VUE_APP_SUPPORT_CHAIN_ID, this.withdraw.receipt.transactionHash)
    }
  }
}
</script>

<style lang="scss">
.art-details {
  text-align: left;

  .header {
    padding: 0 12px;
  }
  .body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .video-container {
      margin-top: 16px;
      margin-bottom: 48px;
      background: transparent;

      .video {
        width: 100%;
        height: 480px;
        border-radius: 16px;
      }
    }

    .info{
      width: 100%;
      padding: 24px;
      background: #fff;
      border-radius: 16px;

      .link{
        padding: 24px 0;
        color: $blueOth;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        animation: blink 1.5s ease infinite;

        @keyframes blink {
          0% {
            color: #9D68FC;
            opacity: 1;
          }
          50% {
            color: #C371FB;
            opacity: 0.5;
          }
          100% {
            color: $blueOth;
            opacity: 1;
          }
      }
      }

      .btn-container {
        .btn {
          width: 180px;
          height: 48px;
          line-height: 48px;
          margin: 48px auto 0;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          border-radius: 8px;
          background: $blueOth;
          cursor: pointer;
          user-select: none;

          .loading-icon {
            font-size: 18px;
            padding-right: 16px;
          }
        }
      }
    }
    .row{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 8px 0;

      .label {
        font-weight: 600;
        padding-right: 24px;
      }

      .content {
        flex: 1;
        color: $valueColor;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .copy-icon {
        margin-left: 8px;
        font-size: 15px;
        color: #333;
        transition: all 0.3s;

        &:hover {
          color: #3A4EFF;
        }
      }
    }
  }
}

@media screen and (width: $mobileWidth) {
  .body-container {
    .video-container {
      .video {
        border-radius: unset;
      }
    }
  }
}
</style>
